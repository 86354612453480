import { AppContext } from './contextprovider';

export function withAppContext(Component) {
  return function WrapperComponent(props) {
    return (
      <AppContext.Consumer>
        {(state) => (
          <Component
            {...props}
            context={state}
          />
        )}
      </AppContext.Consumer>
    );
  };
}
