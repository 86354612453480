import { vars } from './vars';

// TODO:
const WINDOW_WIDTH = window.innerWidth;

// MUST be manually kept in sync with app/src/styles/core/_settings.scss
export const PRESENTATION_MODAL_WIDTH = 1150;
export const PRESENTATION_MODAL_HEIGHT = 604;

export const CHART_SIZE_STANDARD = 'standard';

export const CHART_SIZES = {
  [CHART_SIZE_STANDARD]: {
    width: WINDOW_WIDTH < PRESENTATION_MODAL_WIDTH ? WINDOW_WIDTH : 0,
    height:
      WINDOW_WIDTH < PRESENTATION_MODAL_WIDTH
        ? Number.parseInt(WINDOW_WIDTH * (PRESENTATION_MODAL_HEIGHT / PRESENTATION_MODAL_WIDTH), 10)
        : PRESENTATION_MODAL_HEIGHT,
  },
};

// page ID's
export const PAGE_HOME = 'home';
export const PAGE_DATA = 'data';
export const PAGE_MEDIA = 'media';
export const PAGE_PRESENTATIONS = 'presentations';
export const PAGE_USERS = 'users';
export const PAGE_SETTINGS = 'settings';

// cache durations
export const CACHE_SECONDS = 60;

// locales
export const LOCALE_EN = 'en';
export const LOCALE_EN_GB = 'en-GB';

export const LOCALE_DEFAULT = vars.settings.locale ?? LOCALE_EN_GB;
export const LOCALELANG_DEFAULT = vars.settings.localelang ?? LOCALE_EN;

// currencies
export const CURRENCY_EUR = 'EUR';
export const CURRENCY_GBP = 'GBP';

export const CURRENCY_DEFAULT = CURRENCY_GBP; // TODO: pull from .env?

// display modes
export const DISPLAY_MODE_TABLE_COMPACT = 'table-compact';
export const DISPLAY_MODE_TABLE_EXPANDED = 'table-expanded';
export const DISPLAY_MODE_GRID = 'grid';

// pagination options
export const PAGINATION_PAGE_SIZE_OPTIONS = [15, 45, 90];

export const PAGINATION = {
  pageSize: PAGINATION_PAGE_SIZE_OPTIONS[0],
  pageSizeOptions: PAGINATION_PAGE_SIZE_OPTIONS,
  hidePerPageOptions: false,
};

// sorting options
export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';

// define dataset ID's and labels
export const DATASET_ID_UNIQUE = 'unique';
export const DATASET_ID_TOTAL = 'total';

export const DATASET_LABEL_UNIQUE = 'Unique';
export const DATASET_LABEL_TOTAL = 'Total';

// toast settings
export const TOAST_DURATION_LINGERING = 20000;
export const TOAST_DURATION_IMPORTANT = 7000;
export const TOAST_DURATION_AMBIENT = 3000;
export const TOAST_DURATION_MOMENTARY = 1500;

export const BROWSER_THRESHOLD_MOBILE = 886; // TODO
export const BROWSER_THRESHOLD_MEDIUM_DESKTOP = 1150; // TODO

// how many items can be deleted without the explicit confirmation checkbox appearing in the dialog
export const DELETE_EXPLICIT_CONFIRM_THRESHOLD = 2;

// HTTP status codes
export const HTTP_200_OK = 200;
export const HTTP_304_NOT_MODIFIED = 304;
// export const HTTP_400_BAD_REQUEST = 400;      // TODO: used?
// export const HTTP_401_UNAUTHORIZED = 401;     // TODO: used?
export const HTTP_403_FORBIDDEN = 403;
export const HTTP_409_CONFLICT = 409;
export const HTTP_413_REQUEST_ENTITY_TOO_LARGE = 413;
export const HTTP_415_UNSUPPORTED_MEDIA_TYPE = 415;
export const HTTP_422_UNPROCESSABLE_ENTITY = 422;

// model names
export const MODEL_ID_CLIENT = 'c';
export const MODEL_ID_DIRECTOR = 'd';
export const MODEL_ID_MEDIA = 'm';
export const MODEL_ID_PRESENTATION = 'p';
export const MODEL_ID_USER = 'u';

// side panel ID's
export const PANEL_ID_MEDIA_ITEM = 'panelMediaItem';
export const PANEL_ID_CLIENT = 'panelClient';
export const PANEL_ID_DIRECTOR = 'panelDirector';
export const PANEL_ID_MANAGE_THUMBNAILS = 'panelManageThumbnails';
export const PANEL_ID_RESELECT_THUMBNAIL = 'panelReselectThumbnail';
export const PANEL_ID_PRESENTATION = 'panelPresentation';
export const PANEL_ID_USER = 'panelUser';

// thumbnail selection
export const THUMBNAIL_USE_TYPE_AUTO = 'auto';
export const THUMBNAIL_USE_TYPE_SPECIFIED = 'specified';
export const THUMBNAIL_USE_TYPE_CUSTOM = 'custom';

export const THUMBNAIL_USE_TYPE_MAP = {
  [THUMBNAIL_USE_TYPE_AUTO]: 'Automatically select',
  [THUMBNAIL_USE_TYPE_SPECIFIED]: 'Use selected thumbnail...',
  [THUMBNAIL_USE_TYPE_CUSTOM]: 'Use custom thumbnail...',
};
