import React from 'react';
import { withRouter } from 'react-router-dom';

import Cookies from 'js-cookie';

import { adaptiveFetch } from './helpers';
import { getUserI18nMapFull } from './i18n_strings_override';
import { vars } from './vars';

import { EuiContext } from './vendor/eui/src/components/context';

export const AppContext = React.createContext('app');

// route ID constants
const ROUTE_HOME = 'h';
const ROUTE_PRESENTATION_VIEWER = 'pv';

// unpack specific needed values from the site vars
const { route, errcode, errmsg } = vars;

// Google login error messages
let GOOGLE_LOGIN_ERROR_MESSAGE_SHORT = {};
let GOOGLE_LOGIN_ERROR_MESSAGE_MAIN = {};

// TODO: ensure this is fine for all routes and login states
if (route === ROUTE_HOME) {
  GOOGLE_LOGIN_ERROR_MESSAGE_SHORT = {
    // NOTE: this only needs to be set to override the default 'Login Error'
  };

  GOOGLE_LOGIN_ERROR_MESSAGE_MAIN = {
    // NOTE: this only needs to be set to override the default 'There was an issue logging in'
    // TODO: check these vars are minified appropriately in prod builds
    [errcode.LOGIN1]: errmsg[errcode.LOGIN1],
    [errcode.LOGIN2]: errmsg[errcode.LOGIN2],
    [errcode.LOGIN3]: errmsg[errcode.LOGIN3],
    [errcode.LOGIN4]: errmsg[errcode.LOGIN4],

    [errcode.LOGIN21]: errmsg[errcode.LOGIN21],
    [errcode.LOGIN22]: errmsg[errcode.LOGIN22],
    [errcode.LOGIN23]: errmsg[errcode.LOGIN23],

    // Google errors
    [errcode.idpiframe_initialization_failed]: (
      <>
        Cookies are not enabled in this browser,
        <br />
        or you are in Incognito mode
      </>
    ),
    [errcode.popup_closed_by_user]: errmsg[errcode.popup_closed_by_user],
    [errcode.access_denied]: errmsg[errcode.access_denied],
    [errcode.immediate_failed]: errmsg[errcode.immediate_failed],
  };
}

class GlobalContextProvider extends React.Component {
  vars = vars;

  isLoggingIn = false;

  state = {
    isGlobalLoading: route !== ROUTE_PRESENTATION_VIEWER,
    // isGlobalLoading: true,

    isCreditInactive: false,

    toastCounter: 0,
    toasts: [],

    googleLoginState: {
      isError: false,
    },
    user: {},
  };

  addToast = (toastObj, textObj, particulars, errorObj) => {
    const newState = {
      toastCounter: this.state.toastCounter,
      toasts: [...this.state.toasts],
    };

    // apply an ID to the toast object
    ++newState.toastCounter;
    toastObj.id = `t-${newState.toastCounter}`;

    // if textObj is provided, define the toast title and text values using it
    if (textObj) {
      toastObj.title = typeof textObj.title === 'function' ? textObj.title(particulars, errorObj) : textObj.title;
      toastObj.text = typeof textObj.text === 'function' ? textObj.text(particulars, errorObj) : textObj.text;
    }

    newState.toasts.push(toastObj);

    this.setState(newState);

    return toastObj;
  };

  removeToast = (toastObj) => {
    const newState = {
      toasts: [...this.state.toasts],
    };

    newState.toasts = newState.toasts.filter((toast) => toast.id !== toastObj.id);

    this.setState(newState);
  };

  removeAllToasts = () => {
    const newState = {
      toasts: [],
    };

    this.setState(newState);
  };

  changeGlobalLoadingState = (isLoading) => {
    this.setState({
      isGlobalLoading: isLoading,
    });
  };

  changeGlobalCreditInactiveState = (isInactive) => {
    this.setState({
      isCreditInactive: isInactive,
    });
  };

  changeGoogleLoginErrorState = (newState) => {
    this.setState({
      googleLoginState: newState,
    });
  };

  setGlobalCreditInactiveMomentarily = () => {
    this.setState(
      {
        isCreditInactive: true,
      },
      () => {
        setTimeout(
          () => {
            this.setState({
              isCreditInactive: false,
            });
          },
          7000, // TODO: centralize (increase?)
        );
      },
    );
  };

  onGoogleLogin = (response) => {
    if (this.isLoggingIn) {
      return false;
    }

    // set logging in state flag
    this.isLoggingIn = true;

    // set loading state
    this.changeGlobalLoadingState(true);

    return adaptiveFetch({
      method: 'POST',
      endpoint: '/swap_token',
      data: {
        credential: response?.credential,
      },
      headers: {
        // MAYBE: add custom header, which we then check on the backend?
        'X-Requested-With': 'XMLHttpRequest',
        'X-Google-OAuth2-Type': 'client',
      },
    })
      .then((resp) => {
        // unset loading state, set user info into state
        this.setState({
          isGlobalLoading: false,
          user: resp.user,
        });

        // set cookie
        Cookies.set('LoggedIn', 'true', {
          secure: true,
          sameSite: 'strict',
          expires: new Date(resp.user.access_token_expiry + 'Z'), // Z appended to force date as UTC
        });

        // enrich Rollbar error data with logged in user information
        if (window.Rollbar && typeof window.Rollbar.configure === 'function') {
          window.Rollbar.configure({
            payload: {
              person: {
                id: resp.user.email,
              },
            },
          });
        }

        // unset loading state
        this.changeGlobalLoadingState(false);

        // change page
        this.changePage(this.props.history.location.pathname !== '/' ? this.props.history.location.pathname : '/home');

        // unset logging in state flag
        this.isLoggingIn = false;
      })
      .catch((error) => {
        error.data.then(({ detail }) => {
          // delete LoggedIn local cookie?
          if (detail.code === this.vars.errcode.LOGIN15) {
            Cookies.remove('LoggedIn');
          }

          // set google login error state
          this.changeGoogleLoginErrorState({
            isError: detail.code !== this.vars.errcode.LOGIN15,
            code: detail.code,

            shortMessage: GOOGLE_LOGIN_ERROR_MESSAGE_SHORT[detail.code],
            mainMessage: GOOGLE_LOGIN_ERROR_MESSAGE_MAIN[detail.code],
          });
        });

        // unset loading state
        this.changeGlobalLoadingState(false);

        // navigate to homepage
        this.changePage('/');

        // unset logging in state flag
        this.isLoggingIn = false;
      });
  };

  onGoogleLogout = () => {
    // unset User state
    this.setState({
      user: {},
    });

    // delete cookie
    Cookies.remove('LoggedIn');

    return adaptiveFetch({
      method: 'GET',
      endpoint: '/logout',
    })
      .then(() => {
        // navigate to homepage
        this.changePage('/');
      })
      .catch((error) => {
        console.log('error', error);

        // navigate to homepage
        this.changePage('/');
      });
  };

  changePage = (newLocation, newState) => {
    this.props.history.push(newLocation, newState);
  };

  getHistory = () => {
    return this.props.history;
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          // constants
          vars: vars,

          // state
          isGlobalLoading: this.state.isGlobalLoading,
          isCreditInactive: this.state.isCreditInactive,

          toasts: this.state.toasts,
          googleLoginState: this.state.googleLoginState,
          user: this.state.user,

          // functions
          changeGlobalLoadingState: this.changeGlobalLoadingState,

          changeGlobalCreditInactiveState: this.changeGlobalCreditInactiveState,
          setGlobalCreditInactiveMomentarily: this.setGlobalCreditInactiveMomentarily,

          changeGoogleLoginErrorState: this.changeGoogleLoginErrorState,

          addToast: this.addToast,
          removeToast: this.removeToast,
          removeAllToasts: this.removeAllToasts,

          changePage: this.changePage,
          getHistory: this.getHistory,

          onGoogleLogin: this.onGoogleLogin,
          onGoogleLogout: this.onGoogleLogout,
        }}
      >
        <EuiContext i18n={getUserI18nMapFull(this.state.user?.locale)}>{this.props.children}</EuiContext>
      </AppContext.Provider>
    );
  }
}

export default withRouter(GlobalContextProvider);
